<template>
  <el-select @change="changeRate" filterable v-model="taxRate" placeholder="选择税率" size="small" style="width:130px">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
export default {
  props:{
    taxRate: {
      type: Number,
      default: 1
    },
  },
  data () {
    return {
      options:[
        {
          value: 1,
          label: "全部"
        },
        {
          value: 0.17,
          label: "0.17"
        },
        {
          value: 0.16,
          label: "0.16"
        },
        {
          value: 0.13,
          label: "0.13"
        },
        {
          value: 0.10,
          label: "0.10"
        },
        {
          value: 0.09,
          label: "0.09"
        },
        {
          value: 0.06,
          label: "0.06"
        },
        {
          value: 0.05,
          label: "0.05"
        },
        {
          value: 0.04,
          label: "0.04"
        },
        {
          value: 0.03,
          label: "0.03"
        },
        {
          value: 0.015,
          label: "0.015"
        },

        {
          value: 0.0,
          label: "0.00"
        },
        {
          value: 0.01,
          label: "0.01"
        }
      ],
    }
  },
  methods: {
    changeRate(){
      this.$emit("update:taxRate", this.taxRate)
      this.$emit("success", this.taxRate)
    }
  }
}
</script>