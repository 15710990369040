<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="采集单张发票" v-model="dialogFormVisible">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="分类" :label-width="formLabelWidth">
        <el-radio-group v-model="type" size="small">
          <el-radio label="in">进项</el-radio>
          <el-radio label="out">销项</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分类" :label-width="formLabelWidth">
        <el-radio-group v-model="form.invoiceType" size="small">
          <el-radio :label="1">专票</el-radio>
          <el-radio :label="2">普票</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开票日期" :label-width="formLabelWidth" prop="kprq">
        <el-date-picker size="small" style="width:200px;" v-model="form.kprq" type="date" value-format="YYYYMMDD" placeholder="选择日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="发票代码" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.fpdm" size="small"></el-input>
      </el-form-item>
      <el-form-item label="发票号码" :label-width="formLabelWidth" prop="fphm">
        <el-input style="width:200px" v-model="form.fphm" size="small"></el-input>
      </el-form-item>

      <el-form-item v-if="form.invoiceType == 1" :label="labelWord" :label-width="formLabelWidth" prop="code">
        <el-input style="width:200px" v-model="form.code" size="small"></el-input>
      </el-form-item>

      <el-form-item v-else :label="labelWord2" :label-width="formLabelWidth" prop="code">
        <el-input style="width:200px" v-model="form.code" size="small"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="savekp" size="small" :loading="this.loading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { invoiceCollectionOne  } from '@/api/invoice.js'

export default {
  props:{
    type:String,
    default:''
  }, 
  data(){
    var fphmRule = (rule,value,callback) => {
      if (value === '') {
        this.labelWord = '金额'
        this.labelWord2 = '校验码'
        callback(new Error('请输入发票号码'));
      } else {
        if (this.form.fphm.length == 20) {
          this.labelWord = '价税合计'
          this.labelWord2 = '价税合计'
          callback()
        }else{
          this.labelWord = '金额'
          this.labelWord2 = '校验码'
          callback();
        }
      }
    }
    return {
      dialogFormVisible: false,
      formLabelWidth: "100px",
      form: {
        invoiceType:1,
        type:'in',
        fpdm:"",
        code:"",
        fphm:"",
        kprq:"",
      },

      rules:{
        kprq: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        fphm: [
          { validator: fphmRule, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写校验码/金额', trigger: 'blur' }
        ]
      },
      loading:false,
      labelWord:'金额',
      labelWord2:'校验码'
    }
  },
  
  methods:{
    init(){
      this.dialogFormVisible = true
    },
    savekp(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.form.type = this.type
          this.loading = true
          invoiceCollectionOne(this.form).then(res =>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("采集已完成")
              this.dialogFormVisible = false
              this.$emit("success")
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item{
  margin-bottom: 15px;
}
</style>