<template>
  <div class="app-container" v-loading="loading1">
    <div style="position:absolute;right:31px;z-index:999">
      <qzf-video vid="4467be5374c68239e51ca6a4d45e5659_4"></qzf-video>

      <el-button type="text" @click="sumsAlert">点击查看统计</el-button>
    </div>
    <el-tabs v-model="listQuery.bc" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="全部发票" :name="3"></el-tab-pane>
      <el-tab-pane label="已补充" :name="1"></el-tab-pane>
      <el-tab-pane label="未补充" :name="2"></el-tab-pane>
      <div class="fl">
        <selectInvoiceType v-model:invoiceType="listQuery.invoiceType" @success="getList"></selectInvoiceType>
        <el-input v-model="listQuery.fphm" placeholder="销方名称/发票号码" @keyup.enter.native="getList" style="width:130px" size="small"></el-input>
        <el-button style="margin-right: 5px;" size="small" @click="getList" type="primary" :icon="Search">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:21px 0px 0px 6px;height:277px;width:430px" class="styleForm">
            <el-form-item label="开票日期 ：" :label-width="formLabelWidth">
              <el-date-picker
                size="small"
                v-model="listQuery.rq"
                type="daterange"
                range-separator="-"
                start-placeholder="开始账期"
                end-placeholder="结束账期"
                style="width:80%;margin-left:5px"
              />
            </el-form-item>
            <el-form-item label="税率 ：" :label-width="formLabelWidth" prop="taxId">
              <selectTax v-model:taxRate="listQuery.taxRate" @success="getList"></selectTax>
            </el-form-item>
            <el-form-item label="商品编码 ：" :label-width="formLabelWidth" prop="taxId">
              <ssflbm v-model:ssflbm="listQuery.ssflbm"></ssflbm>
            </el-form-item>
            <el-form-item label="发票来源 ：" :label-width="formLabelWidth">
              <el-select v-model="listQuery.upload" placeholder="请选择来源" size="small" @change="getList">
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="凭证状态 ：" :label-width="formLabelWidth">
              <el-radio-group v-model="listQuery.voucher">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已生成</el-radio>
                <el-radio :label="2">未生成</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="结算状态：" :label-width="formLabelWidth">
              <el-radio-group v-model="listQuery.js">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结算</el-radio>
                <el-radio :label="2">未结算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="fr" style="margin-bottom:10px">
        <el-dropdown split-button type="success" @click="addIncome" size="small" style="margin-right:10px" :hide-on-click="false">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <qzf-button size="small" text @success="openCom">进项导入</qzf-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <qzf-button size="small" text @success="collect">单张采集</qzf-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button size="small" type="danger" @success="delALL()" :loading="loading"   >
          <el-icon><Delete /></el-icon><span  > 批量删除</span>
        </qzf-button>
        <qzf-button type="primary" @success="handleBuchongstatus()" size="small" style="margin-left:10px">
          <el-icon><Memo /></el-icon><span  > 批量补充</span>
        </qzf-button>
        <qzf-button jz="false" size="small" type="warning" @success="handleWzz"   >
          <el-icon><List /></el-icon><span  > 未认证进项列表</span>
        </qzf-button>
        <!-- <el-dropdown size="small" @command="handleSettlement" style="margin-left: 6px;">
          <qzf-button size="small" jz="false" button_code="fp_xx_pljs" type="primary" >
            批量结算<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a">银行</el-dropdown-item>
              <el-dropdown-item command="b">现金</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        <el-dropdown size="small" style="margin-left:10px;margin-right:10px" :hide-on-click="false">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a" @click="handleCommand">
                <span >一键生成凭证</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="bqfp">
                <span >补全发票</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="batchSetting">
                <span >批量设置未认证</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" @click="batchDrawback">
                <span >批量是否退税</span>
              </el-dropdown-item>

              <el-dropdown-item command="h" @click="batchInvoiceType">
                <span >批量修改票面类型</span>
              </el-dropdown-item>
              
              <el-dropdown-item command="e" @click="exportIncome">
                <span >导出</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" @click="singleDayin()">
                <span >单张打印采购明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="g" @click="singleDayin('merge')">
                <span >合并单位打印采购明细</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <settings name="设置" :type="['code_jxse']"></settings>
      </div>
      <div class="top_title clearfix">
        <div class="left_box fl" @click="allSelect">
          <input type="checkbox" :checked="isAllSelected" />
        </div>
        <div class="top-nav right_box fl">
          <el-row>
          <el-col :span="3">
            <div class="box-content" style="border-left:none;">业务类型</div>
          </el-col>
          <el-col :span="6">
            <div class="box-content">开票项目</div>
          </el-col>
          <el-col :span="6">
            <div class="box-content">补充项目</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">数量</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">金额</div>
          </el-col>
          <el-col :span="1">
            <div class="box-content">税率</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">税额</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">价税合计</div>
          </el-col>
          </el-row>
        </div>
      </div>

      <div class="table_height" :style="{height:contentStyleObj}" v-loading="loadingBig">
        <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>
        <div class="content_center clearfix" style="margin-bottom:10px;" v-for="(item,index) in list" :key="index">
          <div class="left_box fl" @click="singleSelect(item, index)">
            <input type="checkbox" :checked="item.checked" />
          </div>
          <div class="content-nav right_box fl">
            <el-row class="content-top">
              <el-col :span="24" class="box-content clearfix">
                <ul class="top_font clearfix">
                  <li style="width:42px" v-if="item.invoiceType  != '2' && item.invoiceType  != '5'">
                    <span style="color:#008000" v-if="item.rzzt == 1">已认证</span>
                    <span style="color:#e6a23c" v-else>未认证</span>
                  </li>
                  <li style="width:57px">
                    <span style="color:green" v-if="item.invoiceType == '1'">（专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '2'">（普）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '3'">（农）</span>
                    <span style="color:green" v-else-if="item.invoiceType == '4'">（电专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '5'">（电普）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '6'">（海关）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '7'">（其他）</span>
                  </li>
                  <el-tooltip effect="dark" :content="item.fphm" placement="top" :disabled="!item.fphm">
                    <li style="width:220px" class="ccyc">发票号码:
                      <span style="color:green" v-if="item.invoiceType == '1' || item.invoiceType == '4'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '2' || item.invoiceType == '5'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '3' || item.invoiceType == '6' || item.invoiceType == '7'">{{item.fphm}}</span>
                    </li>
                  </el-tooltip>
                  
                  <el-tooltip effect="dark" :content="item.corpName" placement="top" :disabled="!item.corpName">
                    <li style="width:210px" class="ccyc">销方:
                      <span>{{item.corpName}}</span>
                    </li>
                  </el-tooltip>
                  <li style="width:136px">开票日期:
                    <span>{{$parseTime(item.invoiceDate, "{y}-{m}-{d}")}}</span>
                  </li>
                  <li style="width:106px">结算状态:
                    <span v-if="item.settlementStatus == 1" style="color:green">已结算</span>
                    <span v-else style="color:red">未结算</span>
                  </li>
                  <li style="width:109px">凭证:
                    <span style="color:#3c6ff2;text-decoration:underline;cursor:pointer;font-weight:600" v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)">{{item.voucherNo}}</span>
                    <span style="color:#3c6ff2;cursor:pointer" v-else @click="showVoucher(item)">生成凭证</span>
                  </li>
                </ul>
                <div class="top_right_btns">
                  <el-tooltip content="查看发票" placement="top">
                    <el-button type="text" size="small" style="padding:0px;padding-bottom: 6px;" :disabled="!item.pdfUrl" @click="checkCollectInvoice(item)">
                      <i class="iconfont icon-lianjie"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip content="编辑" placement="top" >
                    <i @click="goInfo(item.id)" class="iconfont icon-bianji" style="color:#3c6ff2"></i>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top" >
                    <i @click="delInvoice(item)" class="iconfont icon-shanchu" style="color:#3c6ff2"></i>
                  </el-tooltip>
                </div>
              </el-col>
            </el-row>
            <div class="center_big clearfix">
              <div class="content" v-for="(itemw,index2) in item.items" :key="index2">
                <el-row class="for-content">
                  <el-col :span="3">
                    <div class="box-content">{{itemw.businessName}}</div>
                  </el-col>
                  <el-col :span="6">
                    <el-tooltip effect="dark" :content="itemw.name" placement="top" :disabled="!itemw.name">
                      <div class="box-content ccyc">{{itemw.name}}</div>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="6">
                    <el-tooltip effect="dark" :content="itemw.itemName" placement="top" :disabled="!itemw.itemName">
                      <div class="box-content ccyc">{{itemw.itemName}}</div>
                    </el-tooltip>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.count}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.amount}}</div>
                  </el-col>
                  <el-col :span="1">
                    <div class="box-content">{{itemw.taxRate}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.tax}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.total}}</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-row class="bottom-content ">
              <el-col :span="17">
                <div class="box-content">合计</div>
              </el-col>

              <el-col :span="2">
                <div class="box-content">{{item.sumAmount}}</div>
              </el-col>
              <el-col :span="1">
                <div class="box-content"></div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTax}}</div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTotal}}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>


    <div class="bottom_style">
      <div class="bottom_button">
        
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    </el-tabs>

    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="centerDialogVisible" title="提示" width="30%" center>
      <span>
        <selectInvoiceType v-model:invoiceType="batchInvoiceTypes" @success="changeInvoiceType" type="in"></selectInvoiceType>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="batchChangeInvoiceType">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="进项"></qzf-import-back>
    <voucher-show ref="voucherShow" from="1" @success="getList"></voucher-show>
    <inbc @success="inbcSuccess" ref="inbc"></inbc>
    <collectSingle ref="collectSingle" @success="getList()" type="in"></collectSingle>
  </div>
</template>

<script>
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import selectTax from '@/components/Screening/selectTax.vue'
import inbc from './invoiceIn/inbc.vue' 
import selectInvoiceType from "./selectInvoiceType.vue"
import collectSingle from "./collectSingle.vue"
import { inInvoiceList , delInInvoice ,inOutSum, invoiceCollectionBcIn ,saveWrz ,invoiceBcStatus } from '@/api/invoice.js'

import { dayinSellIn } from '@/api/printSet'
import { oneKeyVoucher } from '@/api/voucher'

import { exportReceiptOfReceipts } from "@/api/export"

export default {
  name:'income',
  components: {
    inbc,
    ssflbm,
    selectInvoiceType,
    selectTax,
    collectSingle
  },
  data() {
    return {
      selects: [],
      listQuery: {
        page: 1,
        limit: 20,
        bc: 3,
        taxRate:1,
        rq:[],
        ssflbm:'',
        upload:0,
        voucher:0,
        js:0,
        fphm:'',
        corpName:''
      },
      activeName:'first',
      list:[],
      total: 0,
      contentStyleObj:{}, //高度变化
      formLabelWidth: "100px",
      inputQuery: "",
      fromOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'手动添加'
        },
        {
          value:2,
          label:'导入'
        },
        {
          value:3,
          label:'采集'
        },
      ],
      loading:false,
      loadingBig: false,
      loading1: false,
      centerDialogVisible:false,
      batchInvoiceTypes:'',
      invoiceTypeSelects2:[]
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.list.every(el => {
        return el.checked;
      });
    },
  },
  created () {
    this.getList()
    this.init()
    this.contentStyleObj=this.$getHeight(380)
  },
  methods:{
    init(){
      invoiceBcStatus({type:'in'}).then(res => {
        if(res.data.data.status){
          this.$notify.error({
            title: '警告',
            message: '进项包含未补充完整发票',
            duration:0
          });
        }
      })
    },
    bqfp(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      invoiceCollectionBcIn({}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("成功")
          this.getList()
        }
      })
    },
    //批量补充
    handleBuchongstatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$refs.inbc.init(this.selects)
    },
    inbcSuccess(){
      this.getList()
    },
    getList(){
      this.loadingBig = true
      inInvoiceList(this.listQuery).then(res=>{
        this.loadingBig = false
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    // 删除发票
    delALL(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.invoiceDel(delSelects)
    },
    delInvoice(row){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.invoiceDel([{id: row.id}])
    },
    invoiceDel(ids){
      this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loading = true
        delInInvoice(ids).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      })
    },
    goInfo(id){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$store.dispatch('commons/setParam', {addInInvoiceId: id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addIncome")
      this.$router.push({
        path: "/input/addIncome",
        name: "addIncome"
      });
    },
    addIncome(e) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$store.dispatch('commons/setParam', {addInInvoiceId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addIncome")
      this.$router.push({
        path: "/input/addIncome",  
      });
    },
    //单选
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.list.filter(v=>{return v.checked})
      console.log(this.selects)
    },
    handleWzz(){
      this.$router.push({
        path: "/input/incomeWzz",
      });
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    handleCommand(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.loading1 = true
      oneKeyVoucher({type:'in'}).then(res=>{
        this.loading1 = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.$bus.emit('voucherUpdate')
          this.getList()
        }
      })
    },
    openCom(){
      this.$refs.importBck.handImport()
    },
    // 统计
    sumsAlert() {
      inOutSum({}).then(res=>{
        this.sums = res.data.data.inSum
        this.sums1 = res.data.data.inSum1
        this.sums2 = res.data.data.inSum2
        this.sums3 = res.data.data.inSum3
        this.sums4 = res.data.data.inSum4
        this.sums5 = res.data.data.inSum5
        this.sums6 = res.data.data.inSum6
        this.sums7 = res.data.data.inSum7
        this.sums8 = res.data.data.inSum8
        let str = `<table style="border:1px solid #c2c2c2;" class="content_table" cellspacing="0" width="100%">
              <tr style="background:#f6f6f6;color:#333;font-weight:600;line-height:32px;">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:40%">类型</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:6%"">数量</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:18%"">金额</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:18%"">税额</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:18%"">价税合计</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税专用发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums1.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums1.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums1.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums1.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税普通发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums2.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums2.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums2.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums2.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">海关进口增值税专用缴款通知书</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums3.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums3.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums3.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums3.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">农副产品发票（免税）</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums4.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums4.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums4.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums4.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">其他发票（可抵扣）</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums5.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums5.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums5.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums5.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税电子专用发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums6.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums6.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums6.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums6.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税电子普通发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums7.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums7.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums7.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums7.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">本月认证发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums8.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums8.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums8.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums8.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-right:1px solid #c2c2c2;text-align: center;">合计</td>
                <td style="border-right:1px solid #c2c2c2;text-align: center;">${this.sums.count}</td>
                <td style="border-right:1px solid #c2c2c2;text-align: center;">${this.sums.sum_amount}</td>
                <td style="border-right:1px solid #c2c2c2;text-align: center;">${this.sums.sum_tax}</td>
                <td style="text-align: center;">${this.sums.sum_total}</td>
              </tr>
            </table>`
        this.$alert(str, '发票统计', {
          confirmButtonText:'确定',
          dangerouslyUseHTMLString: true,
        });
      })
    },
    collect(){
      this.$refs.collectSingle.init()
    },
    batchSetting(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let wrzSelects = []
      this.selects.map(v=>{
        wrzSelects.push({
          id:v.id,
          type:'wrz'
        })
      })
      this.$confirm('确认批量设置未认证?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveWrz(wrzSelects).then(res => {
          if(res.data.msg == "success"){
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    // 批量是否退税
    batchDrawback(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      let backSelects = []
      this.selects.map(v=>{
        backSelects.push({
          id:v.id,
          type:'sfts'
        })
      })
      this.$confirm('确认批量退税?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveWrz(backSelects).then(res => {
          if(res.data.msg == "success"){
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    // 批量修改票面类型
    batchInvoiceType(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      this.centerDialogVisible = true
    },
    batchChangeInvoiceType(){
      this.$confirm('确认批量修改票面类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveWrz(this.invoiceTypeSelects2).then(res => {
          if(res.data.msg == "success"){
            this.centerDialogVisible = false
            this.selects = []
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    changeInvoiceType(){
      let invoiceTypeSelects = []
      console.log(this.selects);
      this.selects.map(v=>{
        invoiceTypeSelects.push({
          id:v.id,
          type:'invoiceType',
          invoiceType:this.batchInvoiceTypes
        })
      })
      this.invoiceTypeSelects2 = invoiceTypeSelects
    },


    handleClick(tab) {
      if(tab.props.name == 2){
        this.listQuery.bc = 2
      }else if (tab.props.name == 3){
        this.listQuery.bc = 3
      }else if (tab.props.name == 1){
        this.listQuery.bc = 1
      }
      this.getList()
    },
    singleDayin(type){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      dayinSellIn({ids,type}).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url);
        }
      })
    },
    exportIncome(){
      exportReceiptOfReceipts({}).then(res => {
        window.open(res.data.data.url)
      })
    },
    checkCollectInvoice(item){
      window.open('https://file.listensoft.net' + item.pdfUrl)
    },
    // 批量结算
    // handleSettlement(command){
    //   if(this.$checkSettleStatus() != ""){
    //     this.$message.error(this.$checkSettleStatus())
    //     return 
    //   }
    //   if(this.selects.length == 0){
    //     this.$qzfMessage("请至少选择一条", 1)
    //     return
    //   }
    //   let settleStatus = false
    //   let statusFu = false
    //   let statusPayer = false
    //   this.selects.map(v => {
    //     if(v.settlementStatus == 1){
    //       settleStatus = true
    //     }
    //     if(v.sumTotal < 0){
    //       statusFu = true
    //     }
    //     if(!v.subjectId){
    //       statusPayer = true
    //     }
    //   })
    //   // if(settleStatus){
    //   //   this.$qzfMessage("不能重复结算", 1)
    //   //   return
    //   // }
    //   // if(statusFu){
    //   //   this.$qzfMessage("不允许结算负数！", 1)
    //   //   return
    //   // }
    //   // if(statusPayer){
    //   //   this.$qzfMessage("未补充不允许结算！", 1)
    //   //   return
    //   // }

    //   if (command == "a") {
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "income", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'bank'
    //       }
    //     });
    //   } else if (command == "b") {
    //     //现金跳转
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "income", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'cash'
    //       }
    //     });
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
// ::-webkit-scrollbar {
//     width: 15px;
// }
// /* 滚动槽 */
// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: rgba(0, 0, 0, 0.3);
//   box-shadow: rgba(0, 0, 0, 0.3);
// }
// /* 滚动条滑块 */
// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background: rgba(17, 16, 16, 0.13);
//   -webkit-box-shadow: rgba(0, 0, 0, 0.9);
//   box-shadow: rgba(0, 0, 0, 0.5);
// }
.left_box {
  float: left;
  margin-top: 11px;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.top-nav {
  height: 36px;
  border: 1px solid #c2c2c2;
  background: #eee;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #c2c2c2;
    line-height: 34px;
    font-size: 14px;
    height: 34px;
  }
}
.right_box {
  float: left;
  width: 98%;
}
.for-content {
  .box-content {
    box-sizing: border-box;
    border-left: 1px solid #c2c2c2;
    text-align: center;
    line-height: 36px;
    height: 36px;
    font-size: 14px;
    border-bottom: 1px solid #c2c2c2;
    padding: 0 10px;
  }
  .el-col:nth-child(10) .box-content {
    // border-bottom: none;
    border-right: 1px solid #c2c2c2;
  }
  .el-col:nth-child(1) .box-content {
    // border-bottom: none;
    border-left: none;
  }
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content-nav {
  line-height: 36px;
  border: 1px solid #c2c2c2;
  .content-top {
    background: #f6f6f6;
    .box-content {
      box-sizing: border-box;
      // border-left: 1px solid #eee;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      border-bottom: 1px solid #c2c2c2;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      border-bottom: 1px solid #c2c2c2;
      padding: 0 10px;
    }
    .el-col:nth-child(9) .box-content {
      // border-bottom: none;
      border-right: 1px solid #c2c2c2;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
    .el-col:nth-child(7) .box-content {
      border-right: 1px solid #c2c2c2;
    }
  }
}
.top_font {
  float: left;
  width: 90%;
  margin: 0 auto;
  li {
    font-size: 14px;
    color: #333;
    float: left;
    text-align: left;
    padding-left: 10px;
    margin-right: 2px;
    &.big {
      // width: 260px
    }
  }
}
.top_right_btns {
  float: right;
  // width: 10%;
  margin-right: 16px;
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: $color-main;
    font-size: 18px;
    line-height: 24px;
  }
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>
<style>
.el-message-box{
  width: 800px;
}
.sel_class .el-input__inner{
  border-color: transparent !important;
}
.top_price .el-input-group--append .el-input__inner,.top_price .el-input-group__prepend{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.top_price .el-select .el-input.is-focus .el-input__inner,.top_price .el-select .el-input:hover .el-input__inner{
  border-color: transparent !important;
}
.top_price .el-input-group--prepend .el-input__inner,.top_price .el-input-group__append{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>