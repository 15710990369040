<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补充往来单位" v-model="dialogVisible" width="1200px" >
    <div>
      <div class="top">
        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.corpName" placeholder="公司名"></el-input>
              <qzf-button @success="listFilter()" type="primary">搜索</qzf-button>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <qzf-button @success="bcWldw(1)" type="primary">批量创建</qzf-button>
              <qzf-button @success="bcWldw(2)" type="primary">批量选择</qzf-button>
            </div>
          </el-col>
        </el-row>
      </div>



      <el-table
        height="400"
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>


        <el-table-column align="center" type="selection" width="55" />

        <el-table-column align="left" label="公司" min-width="300">
          <template #default="scope">
            <span v-if="scope.row.corpName">{{scope.row.corpName}}</span>
            <span v-else>手动添加发票未知</span>
          </template>
        </el-table-column>

        <!-- <el-table-column align="left" label="对应公司">
          <template #default="scope">
            <corp-list :corpName="scope.row.corpName" v-model:corpId="scope.row.corpId" type="1"></corp-list>
          </template>
        </el-table-column> -->

        <el-table-column align="left" label="对应科目"  min-width="300">
          <template #default="scope">
            <subject-list width="400" :subjectName="scope.row.corpName" v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId" :codes="$findCode(code)" v-model:status="status" :index="scope.$index"></subject-list>
          </template>
        </el-table-column>

      </el-table>


      <div class="pagination" style="float:none">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="webListQuery.page" v-model:limit="webListQuery.limit" @pagination="pageChange" />
      </div>
    </div>



    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleAddSure" size="small">下一步</el-button>
      </span>
    </template>
  </el-dialog>


  
  <!-- 创建往来单位弹框 -->
  <wldw @success="wldwSuccess" ref="wldw"></wldw>

  <!-- 第二步 -->
  <outbc2 @success="outbc2Success" ref="outbc2"></outbc2>

</template>
<script>
import { outInvoiceBcOneList, outInvoiceBcTwoList, outBcSaveOne } from "@/api/invoice.js"
import wldw from "./wldw"
import outbc2 from "./outbc2"
export default {
  props: {
    
  },
  data () {
    return {
      status: "true",
      webListQuery: {
        page: 1,
        limit: 20,
      },
      listQuery: {
        corpName: '',
      },
      dialogVisible: false,
      list: [],
      pageList: [],
      oldList: [],
      total: 0,
      code: ['code1001','code1002','code1012','code1121','code1122','code1221','code2201','code2202','code2241','code2701','code1123','code2203',],
      selects: [],
    }
  },
  components: {
    wldw,
    outbc2,
  },
  methods: {
    pageChange(){
      let list = this.pageList
      this.total = list.length
      let s = (this.webListQuery.page - 1) * this.webListQuery.limit
      let e = this.webListQuery.page * this.webListQuery.limit
      this.list = list.slice(s, e)
    },
    //初始化
    init(params){
      this.params = params
      outInvoiceBcOneList(params).then(res=>{
        if(res.data.msg == "success"){
          let list = res.data.data.list
          this.oldList = list
          this.pageList = this.oldList
          this.dialogVisible = true
          this.pageChange()
        }
      })
    },
    //过滤
    listFilter(){
      let list = this.oldList.filter(v=>{
        return v.corpName.match(this.listQuery.corpName)
      })
      this.pageList = list
      this.pageChange()
    },
    handleSelectionChange(e){
      this.selects = e
    },
    //批量创建公司
    bcWldw(type){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let items = this.selects.map(v=>{
        return {
          corpName: v.corpName,
          id: v.id,
        }
      })
      this.$refs.wldw.init(items, type, this.code, 1)
    },
    wldwSuccess(e){
      if(!e){
        return
      }
      e.map(v=>{
        this.selects.map(item=>{
          if(v.id == item.id){
            item.subjectId = v.subjectId
            item.fzhsId = v.fzhsId
          }
        })
      })
    },
    //下一步
    handleAddSure(){
      //保存
      // console.log(this.oldList)
      outBcSaveOne(this.oldList).then(res=>{
        this.$store.dispatch("commons/getCorp")
      })
      this.$refs.outbc2.init(this.params)
    },
    outbc2Success(){
      this.$emit("success")
      this.dialogVisible = false
    },
  }
}
</script>
<style lang="less" scoped>
.top{
  margin-bottom: 10px;
}
.bg-purple-light{
  float: right;
}
</style>