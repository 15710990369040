<template>
  <div class="app-container">
    <div style="position:absolute;right:31px;z-index:999">
    <qzf-video vid="4467be5374600147a1d3a3cfeac7d7d1_4"></qzf-video>

      <el-button type="text" @click="sumsAlert">点击查看统计</el-button>
    </div>
    <el-tabs v-model="listQuery.bc" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="全部发票" :name="3"></el-tab-pane>
      <el-tab-pane label="已补充" :name="1"></el-tab-pane>
      <el-tab-pane label="未补充" :name="2"></el-tab-pane>
      
      <div class="fl">
        <selectInvoiceType v-model:invoiceType="listQuery.invoiceType" @success="getList"></selectInvoiceType>
        <el-input v-model="listQuery.fphm" placeholder="购方名称/发票号码" @keyup.enter.native="getList" style="width:150px" size="small"></el-input>
        <el-button style="margin-right:5px;" size="small" @click="getList" type="primary">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:21px 0px 0px 6px;height:307px;width:430px" class="styleForm">
            <el-form-item label="开票日期 ：" :label-width="formLabelWidth">
              <el-date-picker
                size="small"
                v-model="listQuery.rq"
                type="daterange"
                range-separator="-"
                start-placeholder="开始账期"
                end-placeholder="结束账期"
                style="width:80%;margin-left:5px"
              />
            </el-form-item>
            <el-form-item label="税目 ：" :label-width="formLabelWidth" prop="taxId">
              <shuimu v-model:taxId="listQuery.taxId"></shuimu>
            </el-form-item>
            <el-form-item label="税率 ：" :label-width="formLabelWidth" prop="taxId">
              <selectTax v-model:taxRate="listQuery.taxRate" @success="getList"></selectTax>
            </el-form-item>
            <el-form-item label="商品编码 ：" :label-width="formLabelWidth" prop="taxId">
              <ssflbm v-model:ssflbm="listQuery.ssflbm"></ssflbm>
            </el-form-item>
            <el-form-item label="发票来源 ：" :label-width="formLabelWidth">
              <el-select v-model="listQuery.upload" placeholder="请选择来源" size="small" @change="getList">
                <el-option v-for="item in fromOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="凭证状态 ：" :label-width="formLabelWidth">
              <el-radio-group v-model="listQuery.voucher">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已生成</el-radio>
                <el-radio :label="2">未生成</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="结算状态：" :label-width="formLabelWidth">
              <el-radio-group v-model="listQuery.js">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">已结算</el-radio>
                <el-radio :label="2">未结算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="fr" style="margin-bottom:10px">
        <el-dropdown split-button type="success" @click="addOutput" size="small" style="margin-right:10px" :hide-on-click="false">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span size="small" @click="openCom">销项导入</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span size="small" @click="openComShougou" style="margin-top:10px">收购发票导入</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span size="small" @click="collect">单张采集</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button size="small" type="danger" @success="delALL()" :loading="loading"   >
          <el-icon><Delete /></el-icon><span  > 批量删除</span>
        </qzf-button>
        <qzf-button  type="primary" @success="handleBuchongstatus()" size="small"   >
          <el-icon><Memo /></el-icon><span  > 批量补充</span>
        </qzf-button>
        <settings name="设置" :type="['code_xxse']"></settings>

        <!-- <el-dropdown size="small" @command="handleSettlement" style="margin-left: 6px;">
          <qzf-button size="small" jz="false" button_code="fp_xx_pljs" type="primary" >
            批量结算<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a">银行</el-dropdown-item>
              <el-dropdown-item command="b">现金</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->

        <el-dropdown size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" @click="handleCommand">
                <span>一键生成凭证</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="bqfp">
                <span>自动补全发票</span>
              </el-dropdown-item>
              <el-dropdown-item command="e" @click="batchInvoiceType">
                <span >批量修改票面类型</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" @click="batchCorpName">
                <span >批量修改购方名称</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="exportOutput">
                <span>导出</span>
              </el-dropdown-item>
              <el-dropdown-item command="c"  @click="singleDayin()">
                <span>单张打印销售明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" @click="singleDayin('merge')">
                <span >合并单位打印销售明细</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" @click="batchDetail">
                <span >批量补充明细</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="top_title clearfix">
        <div class="left_box fl" @click="allSelect">
          <input type="checkbox" :checked="isAllSelected" />
        </div>
        <div class="top-nav right_box fl">
          <el-row>
          <el-col :span="3">
            <div class="box-content" style="border-left:none;">业务类型</div>
          </el-col>
          <el-col :span="3">
            <div class="box-content">税目</div>
          </el-col>
          <el-col :span="5">
            <div class="box-content">开票项目</div>
          </el-col>
          <el-col :span="4">
            <div class="box-content">补充项目</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">数量</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">金额</div>
          </el-col>
          <el-col :span="1">
            <div class="box-content">税率</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">税额</div>
          </el-col>
          <el-col :span="2">
            <div class="box-content">价税合计</div>
          </el-col>
          </el-row>
        </div>
      </div>

      <div class="table_height" :style="{height:contentStyleObj}" v-loading="loadingBig">
        <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>

        <div class="content_center clearfix" style="margin-bottom:10px;" v-for="(item,index) in list" :key="index">
          <div class="left_box fl" @click="singleSelect(item, index)">
            <input type="checkbox" :checked="item.checked" />
          </div>
          <div class="content-nav right_box fl">
            <el-row class="content-top">
              <el-col :span="24" class="box-content clearfix">
                <ul class="top_font clearfix">
                  <li style="width:62px">
                    <span style="color:green" v-if="item.invoiceType == '1'">（专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '2'">（普）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '3'">（无票）</span>
                    <span style="color:green" v-else-if="item.invoiceType == '4'">（电专）</span>
                    <span style="color:#d56400" v-else-if="item.invoiceType == '5'">（电普）</span>
                  </li>
                  <li v-if="item.openMethod == 2" style="width:10px;color:green">(代)</li>

                  <el-tooltip effect="dark" :content="item.fphm" placement="top" :disabled="!item.fphm">
                    <li style="width:130px" class="ccyc">发票号码:
                      <span style="color:green" v-if="item.invoiceType == '1' || item.invoiceType == '4'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '2' || item.invoiceType == '5'">{{item.fphm}}</span>
                      <span style="color:#d56400" v-if="item.invoiceType == '3'">{{item.fphm}}</span>
                    </li>
                  </el-tooltip>
                  
                  <el-tooltip effect="dark" :content="item.corpName" placement="top" :disabled="!item.corpName">
                    <li style="width:220px" class="ccyc">购方:
                      <span >{{item.corpName}}</span>
                    </li>
                  </el-tooltip>
                  <li style="width:136px">开票日期:
                    <span>{{$parseTime(item.invoiceDate, "{y}-{m}-{d}")}}</span>
                  </li>
                  <li style="width:110px">结算状态:
                    <span v-if="item.settlementStatus == 1" style="color:green">已结算</span>
                    <span v-else style="color:red">未结算</span>
                  </li>
                  <li style="width:110px">凭证：
                    <span style="color:#39b0d2;text-decoration:underline;cursor:pointer;font-weight:600" v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)">{{item.voucherNo}}</span>
                    <span style="color:#39b0d2;;cursor:pointer" v-else type="text" size="small" @click="showVoucher(item)">生成凭证</span>
                  </li>
                </ul>
                <div class="top_right_btns">
                  <el-tooltip content="查看发票" placement="top">
                    <el-button type="text" size="small" style="padding:0px;padding-bottom: 6px;" :disabled="!item.pdfUrl" @click="checkCollectInvoice(item)">
                      <i class="iconfont icon-lianjie"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip content="编辑" placement="top" >
                    <i @click="goInfo(item.id)" class="iconfont icon-bianji" style="color:#3c6ff2"></i>
                  </el-tooltip>
                  <el-tooltip content="删除" placement="top" >
                    <i @click="delInvoice(item)" class="iconfont icon-shanchu" style="color:#3c6ff2"></i>
                  </el-tooltip>
                </div>
              </el-col>
            </el-row>
            <div class="center_big clearfix">
              <div class="content" v-for="(itemw,index2) in item.items" :key="index2">
                <el-row class="for-content">
                  <el-tooltip effect="dark" :content="itemw.subjectName" placement="top" :disabled="!itemw.subjectName">
                    <el-col :span="3">
                      <div class="box-content ccyc">{{itemw.subjectName}}</div>
                    </el-col>
                  </el-tooltip>
                  
                  <el-tooltip effect="dark" :content="itemw.taxName" placement="top" :disabled="!itemw.taxName">
                    <el-col :span="3">
                      <div class="box-content ccyc">{{itemw.taxName}}</div>
                    </el-col>
                  </el-tooltip>
                  <el-tooltip effect="dark" :content="itemw.name" placement="top" :disabled="!itemw.name">
                    <el-col :span="5">
                      <div class="box-content ccyc">{{itemw.name}}</div>
                    </el-col>
                  </el-tooltip>
                  <el-tooltip effect="dark" :content="itemw.itemName" placement="top" :disabled="!itemw.itemName">
                    <el-col :span="4">
                      <div class="box-content ccyc">{{itemw.itemName}}</div>
                    </el-col>
                  </el-tooltip>
                  
                  <el-col :span="2">
                    <div class="box-content">{{itemw.count}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.amount}}</div>
                  </el-col>
                  <el-col :span="1">
                    <div class="box-content">{{itemw.taxRate}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.tax}}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="box-content">{{itemw.total}}</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-row class="bottom-content ">
              <el-col :span="17">
                <div class="box-content">合计</div>
              </el-col>

              <el-col :span="2">
                <div class="box-content">{{item.sumAmount}}</div>
              </el-col>
              <el-col :span="1">
                <div class="box-content"></div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTax}}</div>
              </el-col>
              <el-col :span="2">
                <div class="box-content">{{item.sumTotal}}</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>



      <div class="bottom_style">
        <div class="bottom_button">
        </div>
        <div class="pagination">
          <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
        </div>
      </div>
    </el-tabs>

    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="centerDialogVisible" title="提示" width="30%" center>
      <span>
        <selectInvoiceType v-model:invoiceType="batchInvoiceTypes" @success="changeInvoiceType" type="out"></selectInvoiceType>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="batchChangeInvoiceType">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
 
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="detailDialogVisible" title="补充明细" width="70%" center >

      <div class="table_height"  ref="item" style="margin-top:10px">
      <section class="bank-table-header" v-if="list.length>0">
        <div>商品名称 <i style="margin-left: 5px;" @click="batchDetailChange('name')" class="iconfont icon-piliangcaozuo"></i></div>
        <div>商品编码 <i style="margin-left: 5px;" @click="batchDetailChange('spbm')" class="iconfont icon-piliangcaozuo"></i></div>
        <div>税率</div>
        <div>税目 <i style="margin-left: 5px;" @click="batchDetailChange('taxId')" class="iconfont icon-piliangcaozuo"></i></div>
        <div>操作</div>

      </section>
        
        <!-- 一大条 -->
      <div class="table_height" @scroll="scrollEvent" :style="{height:contentStyleObj2}" v-loading="isLoading">
        <el-empty :image-size="150" v-if="this.detailData.length == 0"></el-empty>

        <section class="bank-item-container" v-for="(item,index) in detailData" :key="index">
          <section class="bank-item-header">
            <el-tooltip effect="dark" :content="item.corpName" placement="top">
              <div class="ccyc" style="width:320px">
                购方名称:<el-input size="small" v-model="item.corpName" style="width:220px"/>
              </div>
            </el-tooltip>
            <div class="ccyc">
              开票日期: <el-date-picker type="date" v-model="item.invoiceDate" placeholder="选择日期" size="small"></el-date-picker>
            </div>
            <!-- <div class="bank-item-header-caozuo"  >
              <el-tooltip content="添加" placement="top">
                <i class="iconfont icon-jiahao" @click="handleAdd(item,bankItem,index2)"></i>
              </el-tooltip>
            </div> -->

          </section>
          <!-- 银行账期的选择 -->
          <!-- 一小条 -->
          <table class="item_box" cellspacing="0" width="100%">
            <tr v-for="(bankItem,index2) in item.items" :key="index2">
              <td>
                <div >
                  <el-input v-model="bankItem.name" size="small"></el-input>
                </div>
              </td>
              <td style="min-width:0">
                <div class="block">
                  <ssflbm v-model:ssflbm="bankItem.spbm"></ssflbm>
                </div>
              </td>
              <td>
                <el-input disabled v-model="bankItem.shuilv" size="small"></el-input>
              </td>
              <td>
                <shuimu v-model:taxId="bankItem.taxId"></shuimu>
              </td>
              <td class="top_right_btns">

                <el-tooltip content="新增" placement="top" >
                  <i class="iconfont icon-jiahao" @click="handleAdd(item,bankItem,index2)" style="color:#3c6ff2"></i>
                </el-tooltip>
                <el-tooltip content="删除" placement="top" v-if="item.items.length > 1">
                  <i class="iconfont icon-shanchu" @click="handleDelete(item,bankItem,index2)" style="color:#3c6ff2"></i>
                </el-tooltip>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="batchDetailSubmit">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <qzf-import-back :hideButton="true" @success="getList()" from="销项" ref="importBack"></qzf-import-back>
    <qzf-import-back :hideButton="true" @success="getList()" from="收购" ref="importBackShougou"></qzf-import-back>
    <voucher-show ref="voucherShow" from="2" @success="getList"></voucher-show>
    <outbc @success="outbcSuccess" ref="outbc"></outbc>
    <collectSingle ref="collectSingle" @success="getList()" type="out"></collectSingle>

  </div>
</template>

<script>
import ssflbm from '@/components/ssflbm/ssflbm.vue'
import shuimu from '@/components/ssflbm/shuimu.vue'
import outbc from './invoiceComponents/outbc.vue' 
import selectInvoiceType from "./selectInvoiceType.vue"
import selectTax from '@/components/Screening/selectTax.vue'

import collectSingle from "./collectSingle.vue"

import { outInvoiceList , delOutInvoice ,inOutSum, invoiceCollectionBcOut ,saveOutWrz , invoiceBcStatus , bqOutInvoice} from '@/api/invoice.js'
import { dayinSell } from '@/api/printSet'

import { oneKeyVoucher } from '@/api/voucher'
import { exportInvoice } from "@/api/export"

function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

export default {
  name:'income',
  components: {
    outbc,
    ssflbm,
    shuimu,
    selectInvoiceType,
    collectSingle,
    selectTax
  },
  data() {
    return {
      selects: [],
      listQuery: {
        page: 1,
        limit: 20,
        bc: 3,
        rq:[],
        taxId:0,
        ssflbm:'',
        upload:0,
        voucher:0,
        js:0,
        taxRate:1
      },
      selectQueryType:'1',
      inputQuery: "",
      activeName:'first',
      list:[],
      total: 0,
      contentStyleObj:{}, //高度变化
      contentStyleObj2:{}, //高度变化

      formLabelWidth: "100px",
      fromOptions:[
        {
          value:0,
          label:'全部'
        },
        {
          value:1,
          label:'手动添加'
        },
        {
          value:2,
          label:'导入'
        },
        {
          value:3,
          label:'采集'
        },
      ],
      loading:false,
      loadingBig: false,
      centerDialogVisible:false,
      batchInvoiceTypes:'',
      invoiceTypeSelects2:[],
      detailDialogVisible:false,
      detailData:[]
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.list.every(el => {
        return el.checked;
      });
    },
  },
  created () {
    this.getList()
    this.init()
    this.contentStyleObj=this.$getHeight(380)
    this.contentStyleObj2=this.$getHeight(460)

  },
  methods:{
    init(){
      invoiceBcStatus({type:'out'}).then(res => {
        if(res.data.data.status){
          this.$notify.error({
            title: '警告',
            message: '销项包含未补充完整发票',
            duration:0
          });
        }
      })
    },
    selectQuery(){
      if(this.selectQueryType == "1"){
        this.listQuery.fphm = this.inputQuery
        this.listQuery.corpName = ''
      }else{
        this.listQuery.corpName = this.inputQuery
        this.listQuery.fphm = ''
      }
      this.getList()
    },
    //批量补充
    handleBuchongstatus(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$refs.outbc.init(this.selects)
    },
    outbcSuccess(){
      this.getList()
    },
    getList(){
      this.loadingBig = true
      outInvoiceList(this.listQuery).then(res=>{
        this.loadingBig = false
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
        }
      })
    },
    // 删除发票
    delALL(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let delSelects = []
      this.selects.map(v=>{
        delSelects.push({
          id:v.id
        })
      })
      this.invoiceDel(delSelects)
    },
    delInvoice(row){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.invoiceDel([{id: row.id}])
    },
    invoiceDel(ids){
      this.$confirm("确认删除", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'
      }).then(()=>{
        this.loading = true
        delOutInvoice(ids).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      })
    },
    goInfo(id){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$store.dispatch('commons/setParam', {addOutInvoiceId: id})
      this.$store.dispatch('tagsView/delCachedViewByName', "addOutput")
      this.$router.push({
        path: "/input/addOutput",
        name: "addOutput"
      });
    },
    addOutput(e) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$store.dispatch('commons/setParam', {addOutInvoiceId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addOutput")
      this.$router.push({
        path: "/input/addOutput",  
      });
    },
    //单选
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.list.filter(v=>{return v.checked})
      console.log(this.selects)
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    bqfp(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      invoiceCollectionBcOut({}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("成功")
          this.getList()
        }
      })
    },
    handleCommand() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      oneKeyVoucher({type:'out'}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.$bus.emit('voucherUpdate')
          this.getList()
        }
      })
    },
    openCom(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.importBack.handImport()
    },
    openComShougou(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.importBackShougou.handImport()
    },
    sumsAlert() {
      inOutSum({}).then(res=>{
        this.sums = res.data.data.outSum
        this.sums1 = res.data.data.outSum1
        this.sums2 = res.data.data.outSum2
        this.sums3 = res.data.data.outSum3
        this.sums4 = res.data.data.outSum4
        this.sums5 = res.data.data.outSum5
        let str = `<table style="border:1px solid #c2c2c2;" class="content_table" cellspacing="0" width="100%">
              <tr style="background:#f6f6f6;color:#333;font-weight:600;line-height:32px;">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:40%">类型</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:6%"">数量</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:18%"">金额</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:18%"">税额</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:18%"">价税合计</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税专用发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums1.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums1.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums1.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums1.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税普通发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums2.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums2.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums2.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums2.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">无票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums3.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums3.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums3.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums3.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税电子专用发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums4.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums4.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums4.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums4.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">增值税电子普通发票</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums5.count}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums5.sum_amount}</td>
                <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">${this.sums5.sum_tax}</td>
                <td style="border-bottom:1px solid #c2c2c2;text-align: center;">${this.sums5.sum_total}</td>
              </tr>
              <tr style="line-height:28px">
                <td style="border-right:1px solid #c2c2c2;text-align: center;">合计</td>
                <td style="border-right:1px solid #c2c2c2;text-align: center;">${this.sums.count}</td>
                <td style="border-right:1px solid #c2c2c2;text-align: center;">${this.sums.sum_amount}</td>
                <td style="border-right:1px solid #c2c2c2;text-align: center;">${this.sums.sum_tax}</td>
                <td style="text-align: center;">${this.sums.sum_total}</td>
              </tr>
            </table>`
        this.$alert(str, '发票统计', {
          confirmButtonText:'确定',
          dangerouslyUseHTMLString: true
        });
      })
    },
    collect(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.collectSingle.init()
    },

    // 批量修改票面类型
    batchInvoiceType(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      this.centerDialogVisible = true
    },
    batchChangeInvoiceType(){
      this.$confirm('确认批量修改票面类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveOutWrz(this.invoiceTypeSelects2).then(res => {
          if(res.data.msg == "success"){
            this.centerDialogVisible = false
            this.selects = []
            this.getList()
            this.$qzfMessage("设置成功")
          }
        })
      })
    },
    // 批量修改购方名称
    batchCorpName(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.selects.length == 0){
        this.$qzfMessage("请选择至少一条数据",1)
        return
      }
      this.$prompt('请输入购方名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.loadingBig = true
          let corpNameSelects = []
          this.selects.map(v=>{
            corpNameSelects.push({
              id:v.id,
              type:'corpName',
              corpName:value
            })
          })
          saveOutWrz(corpNameSelects).then(res => {
            this.loadingBig = false
            if(res.data.msg == "success"){
              this.selects = []
              this.getList()
              this.$qzfMessage("修改成功")
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });       
        });
    },
    changeInvoiceType(){
      let invoiceTypeSelects = []
      this.selects.map(v=>{
        invoiceTypeSelects.push({
          id:v.id,
          type:'invoiceType',
          invoiceType:this.batchInvoiceTypes
        })
      })
      this.invoiceTypeSelects2 = invoiceTypeSelects
    },


    exportOutput(){
      exportInvoice({}).then(res => {
        window.open(res.data.data.url)
      })
    },
    handleClick(tab) {
      if(tab.props.name == 2){
        this.listQuery.bc = 2
        this.getList()
      }else if (tab.props.name == 3){
        this.listQuery.bc = 3
        this.getList()
      }else if (tab.props.name == 1){
        this.listQuery.bc = 1
        this.getList()
      }
    },

    // 打印明细
    singleDayin(type){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let ids = []
      this.selects.map(v=>{
        ids.push(v.id)
      })
      dayinSell({ids,type}).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url);
        }
      })
    },
    // 批量结算
    // handleSettlement(command){
    //   if(this.$checkSettleStatus() != ""){
    //     this.$message.error(this.$checkSettleStatus())
    //     return 
    //   }
    //   if(this.selects.length == 0){
    //     this.$qzfMessage("请至少选择一条", 1)
    //     return
    //   }
    //   let settleStatus = false
    //   let statusFu = false
    //   let statusPayer = false
    //   this.selects.map(v => {
    //     if(v.settlementStatus == 1){
    //       settleStatus = true
    //     }
    //     if(v.sumTotal < 0){
    //       statusFu = true
    //     }
    //     if(!v.subjectId){
    //       statusPayer = true
    //     }
    //   })
    //   // if(settleStatus){
    //   //   this.$qzfMessage("不能重复结算", 1)
    //   //   return
    //   // }
    //   // if(statusFu){
    //   //   this.$qzfMessage("不允许结算负数！", 1)
    //   //   return
    //   // }
    //   // if(statusPayer){
    //   //   this.$qzfMessage("未补充不允许结算！", 1)
    //   //   return
    //   // }

    //   if (command == "a") {
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "output", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'bank'
    //       }
    //     });
    //   } else if (command == "b") {
    //     //现金跳转
    //     this.$router.push({
    //       path: "/input/money",
    //       name: "money",
    //       params: {
    //         settleType: "output", // 录入来源:销项发票
    //         settleData: JSON.stringify(this.selects),
    //         routeSource:'cash'
    //       }
    //     });
    //   }
    // },

    // 批量补充明细
    batchDetail(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      let itemBool = false
      this.selects.map(v => {
        if(v.items){
          itemBool = true
        }
      })
      if(itemBool){
        this.$qzfMessage("已存在明细", 1)
        return
      }
      this.detailData = []    
      this.selects.map(v=>{
        let invoiceDate = ""
        if(!v.invoiceDate.match("0001")){
          invoiceDate = v.invoiceDate
        }
        this.detailData.unshift({
          id: v.id,
          corpName: v.corpName,
          invoiceDate: invoiceDate,
          items: [
          {
            spbm: "",
            name: "",
            taxId: 0,
            shuilv:(v.sumTax/v.sumAmount).toFixed(2),
          }
        ]
        })
      })  
      this.detailDialogVisible = true
    },
    batchDetailChange(e){
      this.detailData.map(v=>{
        v.items.map(vv => {
          vv[e] = this.detailData[0].items[0][e]
        })
      })
    },
    batchDetailSubmit(){
      bqOutInvoice(this.detailData).then(res => {
        if(res.data.msg == "success"){
          this.detailDialogVisible = false
          this.$qzfMessage("补充成功")
          this.getList()
        }
      })
    },

    // 批量补充明细
    handleAdd(item, row, index){
      let clone = deepClone(row);
      delete clone.id;
      item.items.splice(index + 1, 0, clone);
    },
    handleDelete(item, row, index) {
      item.items.splice(index, 1);
    },
    checkCollectInvoice(item){
      window.open('https://file.listensoft.net' + item.pdfUrl)
    },
  }
}
</script>

<style lang="scss" scoped>

.left_box {
  float: left;
  margin-top: 11px;
  width: 2%;
  input {
    width: 80%;
    height: 16px;
  }
}
.top-nav {
  height: 36px;
  border: 1px solid #c2c2c2;
  background: #eee;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #c2c2c2;
    line-height: 34px;
    font-size: 14px;
    height: 34px;
  }
}
.right_box {
  float: left;
  width: 98%;
}
.for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      border-bottom: 1px solid #c2c2c2;
      padding: 0 10px;
    }
    .el-col:nth-child(10) .box-content {
      // border-bottom: none;
      border-right: 1px solid #c2c2c2;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.content-nav {
  line-height: 36px;
  border: 1px solid #c2c2c2;
  .content-top {
    background: #f6f6f6;
    .box-content {
      box-sizing: border-box;
      // border-left: 1px solid #eee;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      border-bottom: 1px solid #c2c2c2;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      border-bottom: 1px solid #c2c2c2;
      padding: 0 10px;
    }
    .el-col:nth-child(9) .box-content {
      // border-bottom: none;
      border-right: 1px solid #c2c2c2;
    }
    .el-col:nth-child(1) .box-content {
      // border-bottom: none;
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #c2c2c2;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
    .el-col:nth-child(7) .box-content {
      border-right: 1px solid #c2c2c2;
    }
  }
}
.top_font {
  float: left;
  width: 90%;
  margin: 0 auto;
  li {
    font-size: 14px;
    color: #333;
    float: left;
    width: 18%;
    text-align: left;
    padding-left: 15px;
    margin-right: 2px;
    &.big {
      // width: 260px
    }
  }
}
.top_right_btns {
  float: right;
  // width: 22%;
  margin-right: 16px;
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: $color-main;
    font-size: 18px;
    line-height: 24px;
  }
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.dfyhm{
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  outline: none;
  width: 250px;
  line-height: 20px;
  margin-top: 6px;
}

.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}

.bank-table-header {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
  > div {
    border-right: 1px solid #c2c2c2;
    text-align: center;
    flex: 1;
  }
  > div:last-child{
    border-right: 0;
  }
  input {
    position: absolute;

    zoom: 120%;
    left: 5px;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}

.bank-item-container {
  margin-bottom: 10px;
  border: 1px solid #c2c2c2;
  border-bottom: 0 !important;
}
.bank-item-header {
  width: 100%;
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 20px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  // display: flex;
  position: relative;
  border-bottom: 1px solid #c2c2c2;
  > div {
    margin-right: 30px;
    display: inline-block;
  }
  .item-input {
    position: absolute;
    left: 5px;
    zoom: 110%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.top_right_btns {
  float: right;
  // width: 22%;
  // margin-right: 15px;
  text-align: center;
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: $color-main;
    font-size: 18px;
    line-height: 24px;
  }
}

.item_box{
  width: 100%;
  font-size: 10px;
  tr{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    td{
      flex: 1;
      line-height: 34px;
      border-color: #c2c2c2!important;
      border-bottom: 1px solid #c2c2c2;
      border-right: 1px solid #c2c2c2;
      padding: 0 10px;
    }
  }
}

.bank-item-header-caozuo {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    line-height: 20px;
  }
}
</style>