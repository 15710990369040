<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close append-to-body title="批量补充业务类型" v-model="dialogVisible" width="25%" >

    <subject-list v-model:subjectId="temp.subjectId" v-model:fzhsItemId="temp.fzhsId" :codes="$findCode(['code1606','code5001','code5051'])"></subject-list>
          
    <inventory-list v-model:inventoryId="temp.itemId" v-model:inventoryType="temp.itemType" :type="fw"></inventory-list>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">确 定</qzf-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>

export default {
  data () {
    return {
      fw: true,
      loading: false,
      dialogVisible: false,
      temp: {
        subjectId: 0,
        fzhsId: 0,
      },
    }
  },
  methods: {
    init(fw){
      this.fw = fw
      this.temp.subjectId = 0
      this.temp.fzhsId = 0
      this.temp.itemId = 0
      this.temp.itemType = ""

      this.dialogVisible = true
    },
    handleAddSure(){
      if(this.temp.subjectId == 0 || this.temp.itemId == 0){
        this.$qzfMessage("请选择", 1)
        return
      }
      this.$emit("success", this.temp)
      this.dialogVisible = false
     
    }
  }
}
</script>