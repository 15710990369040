<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补充业务类型" v-model="dialogVisible" width="1400px" >

    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="批量补充" name="批量补充"></el-tab-pane>
        <el-tab-pane label="自动补充" name="自动补充"></el-tab-pane>
      </el-tabs>
    </div>


    <div v-if="activeName == '自动补充'">

      <el-form ref="formRef" label-width="120px">
        <el-form-item label="业务类型">
          <subject-list v-model:subjectId="zdbc.subjectId" v-model:fzhsItemId="zdbc.fzhsId" :codes="$findCode(['code1606','code5001','code5051'])"></subject-list>
        </el-form-item>

        <el-form-item label="商品分类">
          <el-radio-group @change="changeZdbcType" v-model="zdbc.type">
            <el-radio :label="1">自选存货</el-radio>
            <el-radio :label="2">匹配创建下级科目</el-radio>
            <el-radio :label="3">绑定已有科目</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="zdbc.type == 1" label="存货列表">
          <inventory-list v-model:inventoryId="zdbc.inventoryId"></inventory-list>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 2" label="上级科目">
          <subject-list-all v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list-all>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 3" label="对应科目">
          <subject-list v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list>
        </el-form-item>
      </el-form>
    </div>


    <div v-if="activeName == '批量补充'">
      <div class="top">
        <el-row>
          <el-col :span="18">
            <div class="grid-content bg-purple">
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.corpName" placeholder="公司名"></el-input>
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.kpxm" placeholder="开票项目"></el-input>
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.spec" placeholder="规格型号"></el-input>
              <el-input style="width:100px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.taxRate" placeholder="税率"></el-input>
            

              <qzf-button @success="listFilter()" type="primary" size="small">搜索</qzf-button>

              <ssflbm :all="true" v-model:ssflbm="webListQuery.ssflbm" @success="listFilterSsflbm"></ssflbm>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <qzf-button @success="plbc()" type="primary" size="small">批量补充业务类型</qzf-button>
              <qzf-button @success="plxgsm()" type="primary" size="small">批量修改税目</qzf-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-table
        border
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        height="500px">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>


        <el-table-column align="center" type="selection" width="55" />

        <el-table-column align="left" label="公司" min-width="290">
          <template #default="scope">
            <span v-if="scope.row.corpName">{{scope.row.corpName}}</span>
            <span v-else>手动添加发票未知</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="开票项目" min-width="180">
          <template #default="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="规格型号" min-width="300">
          <template #default="scope">
            <span>{{scope.row.spec}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="税率" min-width="90">
          <template #default="scope">
            <span>{{scope.row.taxRate}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" width="500" label="对应科目">
          <template #default="scope">
            <subject-list v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId" :codes="$findCode(['code1606','code5001','code5051'])"  v-model:status="status" :index="scope.$index"></subject-list>

            <!-- <span v-if="scope.row.ssflbm.length >= 2 && scope.row.ssflbm.slice(0,2) != 01">

            </span> -->
            <inventory-list v-model:status="status2" :index="scope.$index" :inventoryName="scope.row.name" :inventorySpec="scope.row.spec" :inventoryUnit="scope.row.unit" v-model:inventoryId="scope.row.itemId" v-model:inventoryType="scope.row.itemType" :type="scope.row.ssflbm.length >= 2 && scope.row.ssflbm.slice(0,2) != '10'"></inventory-list>
          
          </template>
        </el-table-column>


        <el-table-column align="left" label="税目" min-width="290">
          <template #default="scope">
            <shuimu v-model:taxId="scope.row.taxId"></shuimu>
          </template>
        </el-table-column>

      </el-table>

      <div class="pagination" style="float:none">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="webListQuery.page" v-model:limit="webListQuery.limit" @pagination="pageChange" />
      </div>
    </div>



    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">上一步</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">完成</qzf-button>
      </span>
    </template>
  </el-dialog>


  
  <!-- 批量补充弹框 -->
  <ywlx @success="ywlxSuccess" ref="ywlx"></ywlx>


  <!-- 批量补充税目弹框 -->
  <sm @success="smSuccess" ref="sm"></sm>


</template>
<script>
import { outInvoiceBcTwoList, outBcSaveTwo, outAutomaticBc } from "@/api/invoice.js"
import ywlx from "./ywlx"
import sm from "./sm"
import shuimu from '@/components/ssflbm/shuimu.vue'
import ssflbm from '@/components/ssflbm/ssflbm.vue'
export default {
  props: {
    
  },
  data () {
    return {
      status: "true",
      status2: "true",
      zdbc: {
        type: 1,
        subjectId: 0,
        fzhsId: 0,
        inventoryId: 0,
        inventorySubjectId: 0,
      },
      codes: ['code1285','code1403','code1405','code1406','code1408','code1411','code1412','code1421','code1605'],
      activeName: "批量补充",
      loading: false,
      webListQuery: {
        ssflbm: '',
        page: 1,
        limit: 20,
      },
      listQuery: {
        kpxm: '',
        corpName: '',
        spec: "",
        taxRate:""
      },
      dialogVisible: false,
      list: [],
      pageList: [],
      total: 0,
      oldList: [],
      selects: [],
    }
  },
  components: {
    sm,
    ssflbm,
    shuimu,
    ywlx
  },
  methods: {
    pageChange(){
      let list = this.pageList
      this.total = list.length
      let s = (this.webListQuery.page - 1) * this.webListQuery.limit
      let e = this.webListQuery.page * this.webListQuery.limit
      this.list = list.slice(s, e)
    },
    //初始化
    init(params){
      this.zdbc.items = params //用于自动补充
      outInvoiceBcTwoList(params).then(res=>{
        if(res.data.msg == "success"){
          let list = res.data.data.list
          
          this.oldList = list
          this.pageList = this.oldList
          this.pageChange()
          this.dialogVisible = true
        }
      })
    },
    listFilterSsflbm(e){
      this.listFilter(e)
    },
    //过滤
    listFilter(e){
      let list = this.oldList.filter(v=>{
        return v.corpName.match(this.listQuery.corpName) && v.name.match(this.listQuery.kpxm) && v.spec.match(this.listQuery.spec) && (v.taxRate == this.listQuery.taxRate || this.listQuery.taxRate == "")
      })
      if(e){
        list = list.filter(v=>{
          return !e || e == v.ssflbm.slice(0, 2)
        })
      }
      this.pageList = list
      this.pageChange()
    },
    handleSelectionChange(e){
      this.selects = e
    },
    plxgsm(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$refs.sm.init()
    },
    smSuccess(e){
      if(!e){
        return
      }
      this.selects.map(item=>{
        item.taxId = e.taxId
      })
    },
    //批量创建公司
    plbc(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      var ssflbm = ''
      var status = true
      this.selects.map(v=>{
        let s = v.ssflbm.slice(0, 2)
        if(ssflbm == "" || ssflbm == s){
          ssflbm = s
        }else{
          status = false
        }
      })
      if(!status){
        this.$qzfMessage("提示：请先使用“筛选”功能区分“服务”和“货物”，再进行批量补充！", 1)
        return
      }
      //存货的 异常服务
      if(ssflbm == '10'){
        this.$refs.ywlx.init(false)
      }else{
        this.$refs.ywlx.init(true)
      }
    },
    ywlxSuccess(e){
      if(!e){
        return
      }
      this.selects.map(item=>{
        item.subjectId = e.subjectId
        item.fzhsId = e.fzhsId
        item.itemId = e.itemId
        item.itemType = e.itemType
      })
    },
    //完成
    handleAddSure(){
      //保存
      if(this.activeName == "批量补充"){
        this.loading = true
        outBcSaveTwo(this.oldList).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$emit("success")
            this.dialogVisible = false
          }
        })
      } else if(this.activeName == "自动补充"){
        if(this.zdbc.subjectId == 0){
          this.$qzfMessage("请选择业务类型", 1)
          return
        }
        if(this.zdbc.type == 1 && !this.zdbc.inventoryId){
          this.$qzfMessage("请选择存货", 1)
          return
        }
        if((this.zdbc.type == 2 || this.zdbc.type == 3) && !this.zdbc.inventorySubjectId){
          this.$qzfMessage("请选择存货科目", 1)
          return
        }
        this.loading = true
        outAutomaticBc(this.zdbc).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$emit("success")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('voucherUpdate')

            this.dialogVisible = false
          }
        })
      }
    },
    //自动补充
    changeZdbcType(){
      this.zdbc.inventorySubjectId = 0
    }
  }
}
</script>
<style lang="less" scoped>
.top{
  margin-bottom: 10px;
}
.bg-purple-light{
  float: right;
}
</style>