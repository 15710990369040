<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补充业务类型" v-model="dialogVisible" width="1400px" >

    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="批量补充" name="批量补充"></el-tab-pane>
        <el-tab-pane label="存货自动补充" name="存货自动补充"></el-tab-pane>
      </el-tabs>
    </div>


    <div v-if="activeName == '存货自动补充'">

      <el-form ref="formRef" label-width="120px">
        <el-form-item label="业务类型">
          <!-- <subject-list v-model:subjectId="zdbc.subjectId" v-model:fzhsItemId="zdbc.fzhsId" :codes="$findCode(['code1606','code5001','code5051'])"></subject-list> -->
          
          <el-select v-model="zdbc.businessTypeId" class="m-2" placeholder="业务类型" size="small">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>


        </el-form-item>

        <el-form-item label="商品分类">
          <el-radio-group @change="changeZdbcType" v-model="zdbc.type">
            <el-radio :label="1">自选存货</el-radio>
            <el-radio :label="2">匹配创建下级科目</el-radio>
            <el-radio :label="3">绑定已有科目</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="zdbc.type == 1" label="存货列表">
          <inventory-list v-model:inventoryId="zdbc.inventoryId"></inventory-list>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 2" label="上级科目">
          <subject-list-all v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list-all>
        </el-form-item>
        <el-form-item v-else-if="zdbc.type == 3" label="对应科目">
          <subject-list v-model:subjectId="zdbc.inventorySubjectId" :codes="$findCode(codes)"></subject-list>
        </el-form-item>
      </el-form>
    </div>


    <div v-if="activeName == '批量补充'">
      <div class="top">
        <el-row>
          <el-col :span="18">
            <div class="grid-content bg-purple">
              <el-input style="width:200px;margin-right:10px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.corpName" placeholder="公司名"></el-input>
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.kpxm" placeholder="开票项目"></el-input>
              <el-input style="width:200px" @keyup.enter.native="listFilter()" size="small" v-model="listQuery.spec" placeholder="规格型号"></el-input>
              <qzf-button @success="listFilter()" type="primary">搜索</qzf-button>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <qzf-button @success="plbc()" type="primary">批量补充</qzf-button>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-table
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>


        <el-table-column align="center" type="selection" />

        <el-table-column align="left" label="公司" min-width="150">
          <template #default="scope">
            <span v-if="scope.row.corpName">{{scope.row.corpName}}</span>
            <span v-else>手动添加发票未知</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="开票项目" min-width="250">
          <template #default="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="规格型号" min-width="120">
          <template #default="scope">
            <span>{{scope.row.spec}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" label="税率" min-width="80">
          <template #default="scope">
            <span>{{scope.row.taxRate}}</span>
          </template>
        </el-table-column>

        <el-table-column align="left" min-width="300" label="对应项目">
          <template #default="scope">
            <business-type v-model:businessId="scope.row.businessTypeId" v-model:itemId="scope.row.itemId" v-model:fzhsId="scope.row.fzhsId" v-model:itemType="scope.row.itemType" :name="scope.row.name" :spec="scope.row.spec" :unit="scope.row.unit" type="in"></business-type>
          </template>
        </el-table-column>


      </el-table>
      <div class="pagination" style="float:none">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="webListQuery.page" v-model:limit="webListQuery.limit" @pagination="pageChange" />
      </div>
    </div>



    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">上一步</el-button>
        <qzf-button :loading="loading" type="primary" @success="handleAddSure" size="small">完成</qzf-button>
      </span>
    </template>
  </el-dialog>


  
  <!-- 批量补充弹框 -->
  <ywlx @success="ywlxSuccess" ref="ywlx"></ywlx>
</template>
<script>
import { inInvoiceBcTwoList, inBcSaveTwo, inAutomaticBc } from "@/api/invoice.js"
import ywlx from "./ywlx"
export default {
  props: {
    
  },
  computed: {
    options(){
      let data = this.$store.getters["commons/businessType"].in
      return data.slice(0,11)//前11个是存货
    },
  },
  data () {
    return {
      zdbc: {
        type: 1,
        subjectId: 0,
        fzhsId: 0,
        inventoryId: 0,
        inventorySubjectId: 0,
      },
      codes: ['code1285','code1403','code1405','code1406','code1408','code1411','code1412','code1421','code1605'],
      activeName: "批量补充",
      loading: false,
      listQuery: {
        corpName: '',
        kpxm: '',
        spec: '',
      },
      webListQuery: {
        ssflbm: '',
        page: 1,
        limit: 20,
      },
      dialogVisible: false,
      list: [],
      pageList: [],
      total: 0,
      oldList: [],
      selects: [],
    }
  },
  components: {
    ywlx
  },
  methods: {
    pageChange(){
      let list = this.pageList
      this.total = list.length
      let s = (this.webListQuery.page - 1) * this.webListQuery.limit
      let e = this.webListQuery.page * this.webListQuery.limit
      this.list = list.slice(s, e)
    },
    //初始化
    init(params){
      this.zdbc.items = params //用于自动补充
      inInvoiceBcTwoList(params).then(res=>{
        if(res.data.msg == "success"){
          let list = res.data.data.list
          this.oldList = list
          this.pageList = this.oldList
          this.pageChange()
          this.dialogVisible = true
        }
      })
    },
    //过滤
    listFilter(){
      
      let list = this.oldList.filter(v=>{
        return v.corpName.match(this.listQuery.corpName) && v.name.match(this.listQuery.kpxm) && v.spec.match(this.listQuery.spec)
      })
      this.pageList = list
      this.pageChange()
    },
    handleSelectionChange(e){
      this.selects = e
    },
    //批量创建公司
    plbc(){
      if(this.selects.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$refs.ywlx.init()
    },
    ywlxSuccess(e){
      if(!e){
        return
      }
      this.selects.map(item=>{
        item.businessTypeId = e.businessTypeId
        item.fzhsId = e.fzhsId
        item.itemId = e.itemId
        item.itemType = e.itemType
      })
    },
    //完成
    handleAddSure(){
      //保存
      if(this.activeName == "批量补充"){
        this.loading = true
        inBcSaveTwo(this.oldList).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$emit("success")
            this.dialogVisible = false
          }
        })
      } else if(this.activeName == "存货自动补充"){
        if(this.zdbc.businessTypeId == 0){
          this.$qzfMessage("请选择业务类型", 1)
          return
        }
        if(this.zdbc.type == 1 && !this.zdbc.inventoryId){
          this.$qzfMessage("请选择存货", 1)
          return
        }
        if((this.zdbc.type == 2 || this.zdbc.type == 3) && !this.zdbc.inventorySubjectId){
          this.$qzfMessage("请选择存货科目", 1)
          return
        }
        this.loading = true
        inAutomaticBc(this.zdbc).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            if(res.data.data.status){
              this.$qzfMessage("存在非存货类明细，请查看为补充发票重新手动补充")
            }
            this.$emit("success")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getInventory")
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('voucherUpdate')

            this.dialogVisible = false
          }
        })
      }
    },
    //自动补充
    changeZdbcType(){
      this.zdbc.inventorySubjectId = 0
    }
  }
}
</script>
<style lang="less" scoped>
.top{
  margin-bottom: 10px;
}
.bg-purple-light{
  float: right;
}
</style>
<style scoped lang="scss">
 
</style>